import { useState } from "react";
import "./App.css";
import postcodes from "./postcodes.json";

function App() {
	const [search, setsearch] = useState(false);
	const [valid, setvalid] = useState(false);
	const [res, setres] = useState("");

	const handleChange = (e) => {
		setsearch(e.target.value);
		const check = e.target.value.replace(/\s/g, "").toUpperCase();

		let match = postcodes.find((item) => {
			// console.log(item.postcode.replace(/\s/g, ""), check);
			return item.postcode.replace(/\s/g, "").toUpperCase() === check;
		});

		if (match !== undefined) {
			setres(`${check} is a valid ${match.authority} postcode!`);
			setvalid(true);
		} else {
			setres(`${check} is a not a valid postcode for any of our authorities!`);
			setvalid(false);
		}
	};

	if (search.toString().toLowerCase() === "tim rules") {
		return <div className="app2">Correct.</div>;
	}

	if (search.toString().toLowerCase() === "truth") {
		return <div className="app2">Lies.</div>;
	}

	if (search.toString().length >= 15) {
		return <div className="app2">Woah. You good?</div>;
	}

	return (
		<div className={`App ${valid ? "valid" : "notvalid"}`}>
			<h1>Postcode Checker</h1>
			<p>
				This postcode checker is not perfect, there are some postcodes that are
				valid but are missing from the checker. If you think a postcode is valid
				but the postcode checker doesn't confirm this then please check&nbsp;
				<a href="https://www.gov.uk/find-local-council">
					on the government website
				</a>
				.
			</p>
			<p>
				If their postcode shows that one of our funded areas looks after
				education services then they would be eligible for funding.
			</p>
			<p>
				Please send any missing postcodes to&nbsp;
				<a href="mailto:rich.saunders@techeducators.co.uk">Rich</a>
			</p>
			<details>
				<summary>We support the following HEY areas</summary>
				<ul>
					HU1 / HU2 / HU3 / HU4 / HU5 / HU6 / HU7 / HU8 / HU9 / HU10 / HU11 /
					HU12 / HU13 / HU14 / HU15 / HU16 / HU17 / YO25 / YO43 / YO15 / DN15 /
					DN16 / DN17 / DN18 / DN19
				</ul>
			</details>
			<input
				type="text"
				onChange={(event) => handleChange(event, postcodes)}
				placeholder="Postcode"
			/>
			{/* Show the positive message if the postcode is valid */}
			{search !== "" && <p>{res}</p>}

			{search === "" && (
				<p className="hidden-message">Type "Tim rules", see what happens</p>
			)}

			<a
				href="https://wollivan.dev"
				className="wollivan"
				target="_blank"
				rel="noreferrer"
			>
				Magic
			</a>
		</div>
	);
}

export default App;
